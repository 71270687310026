<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 7.5C12.5 10.2614 10.2614 12.5 7.5 12.5M12.5 7.5C12.5 4.73858 10.2614 2.5 7.5 2.5M12.5 7.5H2.5M7.5 12.5C4.73858 12.5 2.5 10.2614 2.5 7.5M7.5 12.5C8.75064 11.1308 9.46172 9.35398 9.50034 7.5C9.46172 5.64602 8.75064 3.86918 7.5 2.5M7.5 12.5C6.24936 11.1308 5.53897 9.35398 5.50034 7.5C5.53897 5.64602 6.24936 3.86918 7.5 2.5M2.5 7.5C2.5 4.73858 4.73858 2.5 7.5 2.5M3.75 12.5C3.75 13.1904 3.19036 13.75 2.5 13.75C1.80964 13.75 1.25 13.1904 1.25 12.5C1.25 11.8096 1.80964 11.25 2.5 11.25C3.19036 11.25 3.75 11.8096 3.75 12.5ZM13.75 12.5C13.75 13.1904 13.1904 13.75 12.5 13.75C11.8096 13.75 11.25 13.1904 11.25 12.5C11.25 11.8096 11.8096 11.25 12.5 11.25C13.1904 11.25 13.75 11.8096 13.75 12.5ZM3.75 2.5C3.75 3.19036 3.19036 3.75 2.5 3.75C1.80964 3.75 1.25 3.19036 1.25 2.5C1.25 1.80964 1.80964 1.25 2.5 1.25C3.19036 1.25 3.75 1.80964 3.75 2.5ZM13.75 2.5C13.75 3.19036 13.1904 3.75 12.5 3.75C11.8096 3.75 11.25 3.19036 11.25 2.5C11.25 1.80964 11.8096 1.25 12.5 1.25C13.1904 1.25 13.75 1.80964 13.75 2.5Z"
      stroke="white"
      stroke-opacity="0.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
